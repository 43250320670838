import styled from "styled-components";
import {useEffect, useRef} from "react";
import {motion} from "framer-motion";

export interface PrimaryButtonProps {
    title: string,
    onClick: () => void,
    fill: boolean
}

export const PrimaryButton = (props: PrimaryButtonProps) => {
    let rootRef = useRef<HTMLDivElement>(null)
    let labelRef = useRef<HTMLLabelElement>(null)

    useEffect(() => {
        if (rootRef.current !== null && labelRef.current !== null) {
            if (props.fill) {
                rootRef.current.style.background = "#f6dd50"
                rootRef.current.style.borderWidth = "0px"
                labelRef.current.style.color = "#fff"
            }
        }
    }, [props.fill])

    return <Root ref={rootRef} whileTap={{backgroundColor : "#ddc648"}} onClick={props.onClick}>
        <Label ref={labelRef}>{props.title}</Label>
    </Root>
}

const Root = styled(motion.div) `
    width: calc(100% + 19px);;
    height: 60px;
    display: flex;
    background: white;
    border: 1px solid #d5b306;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`

const Label = styled.label `
    font-size: 1.5rem;
    font-weight: 600;
    color: #5f5f60;
`

export default PrimaryButton;