import styled from "styled-components";
import {memo, useCallback, useEffect} from "react";
import SectionContainer from "../../component/section/SectionContainer";
import {motion} from "framer-motion";
import ContactKakao from "../../component/button/ContactKakao";

export const FooterInfo = () => {

    let MemorizedContactKakao = memo(ContactKakao)

    let handleResize = useCallback(() => {
        console.log(`width : ${window.innerWidth}`)

    }, [])

    let OnClickWiredConsent = useCallback(() => {
        window.open("https://www.ictmarket.or.kr:8443/precon/pop_CertIcon.do?PRECON_REQ_ID=PRE0000160340")
    }, [])

    useEffect(() => {
        window.addEventListener("resize", handleResize)
        return window.removeEventListener("resize", handleResize)
    }, []);

    return <Root>
        <MemorizedContactKakao/>
        <SectionContainer>
            <ContentContainer>
                <LeftContainer>
                    <LeftFlexContainer>
                        <Text2>사이트명 :</Text2>
                        <TextBold>&nbsp;링딩동(RDD)</TextBold>
                        <TextDivider/>
                        <Text2>대표자 :</Text2>
                        <TextBold>&nbsp;강석효</TextBold>
                        <TextDivider/>
                        <Text2>개인정보 책임관리자 :</Text2>
                        <TextBold>&nbsp;강범석 (official@rdd.co.kr)</TextBold>
                    </LeftFlexContainer>
                    <LeftFlexContainer>
                        <Text2>사업자등록번호 :</Text2>
                        <TextBold>&nbsp;650-09-02830</TextBold>
                        <TextDivider/>
                        <Text2>통신판매업 : </Text2>
                    </LeftFlexContainer>
                    <LeftFlexContainer>
                        <Text2>주소 :</Text2>
                        <TextBold>&nbsp;대구광역시 남구 중앙대로22길 236-2, 202호</TextBold>
                        <TextDivider/>
                        <Text2>대표번호 :</Text2>
                        <TextBold>&nbsp;010-3446-9299</TextBold>
                    </LeftFlexContainer>
                </LeftContainer>
            </ContentContainer>

            <SectionDivider/>

            <ContentContainer>
                <LeftContainer>
                    <ButtonsContainer>
                        <TitleText>BELL Coperation</TitleText>
                        <BoldTextButton whileHover={{color: "#eeeb13"}}>개인정보처리방침</BoldTextButton>
                        <BoldDivider/>
                        <BoldTextButton whileHover={{color: "#eeeb13"}}>서비스 이용약관</BoldTextButton>
                        <BoldDivider/>
                        <BoldTextButton whileHover={{color: "#eeeb13"}}>마케팅 정보수신 동의</BoldTextButton>
                    </ButtonsContainer>
                    <Text>Copyright 2024 BELL All rights reserved.</Text>
                </LeftContainer>
                <RightContainer>
                    <RightFlexContainer>
                        <ConsentFormImage
                            src={"https://www.ictmarket.or.kr:8443/getCertIcon.do?cert_icon=KL22020459852Y001"}
                            onClick={OnClickWiredConsent}/>
                        <ImageDescriptionContainer>
                            <ImageText>
                                {
                                    `유선통신서비스 판매점\n
                                    사전승낙서판매점`
                                }
                            </ImageText>
                        </ImageDescriptionContainer>
                    </RightFlexContainer>
                </RightContainer>
            </ContentContainer>
        </SectionContainer>
    </Root>
}

const Root = styled.footer`
    width: 100%;
    height: auto;
    background: white;
    display: block;
    padding-top: 32px;
    padding-bottom: 32px;
    border-top: 1px solid #e8e8e8;
    z-index: 0;
`

const LeftContainer = styled.div `
    width: 100%;
    height: auto;
    display: block;
    float: left;
    justify-content: left;
`

const RightContainer = styled.div `
    width: 80%;
    height: auto;
    display: block;
    float: right;
    justify-content: right;
`

const LeftFlexContainer = styled.div `
    width: 100%;
    height: auto;
    display: flex;
    float: left;
    justify-content: left;
`

const RightFlexContainer = styled.div `
    width: 100%;
    height: auto;
    display: flex;
    float: right;
    justify-content: right;
    align-items: center;
`

const ButtonsContainer = styled.div `
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    display: flex;
    float: left;
    justify-content: left;
    align-items: center;
`

const SectionDivider = styled.div `
    width: 100%;
    height: 1px;
    border-radius: 5px;
    background-color: #d3d3d3;
    margin-top: 32px;
    margin-bottom: 32px;
`

const ContentContainer = styled.div `
    width: 100%;
    height: auto;
    display: flex;
`

const BoldTextButton = styled(motion.span) `
    width: auto;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    font-family: Lato-Light;
    font-size: 14px;
    font-weight: bold;
    color: #787878;
    letter-spacing: -.5px;
    line-height: 1.5;
`

const TextButton = styled.span `
    width: auto;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    font-family: Lato-Light;
    font-size: 14px;
    font-weight: 400;
    color: #787878;
    letter-spacing: -.5px;
    line-height: 1.5;
`

const BoldDivider = styled.div `
    width: 1px;
    height: 20px;
    border-radius: 5px;
    background-color: #d3d3d3;
    align-content: center;
    justify-content: center; 
`

const ConsentFormImage = styled.img `
    width: 50px;
    height: 50px;
    float: right;
    cursor: pointer;
`

const ImageDescriptionContainer = styled.div `
    width: auto;
    height: auto;
    display: inline-block;
    margin-left: 16px;
    margin-right: 16px;
    text-align: center;
    align-items: center;
    justify-content: center;
    vertical-align: center;
`

const ImageText = styled.p `
    width: 100%;
    height: 100%;
    margin: 0 auto;
    vertical-align: center;
    font-size: 10px;
    font-weight: bold;
    font-family: Lato-Light;
    color: #aaaaaa;
    line-height: .5;
    letter-spacing: -.5px;
    white-space: pre-line;
`

const TitleText = styled.span `
    width: auto;
    height: auto;
    margin-right: 30px;
    font-size: 16px;
    font-weight: bold;
    font-family: Lato-Light;
    color: #787878;
`

const Text = styled.div `
    width: auto;
    height: auto;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: normal;
    font-family: Lato-Light;
    color: #787878;
    letter-spacing: -.5px;
`

const Text2 = styled.div `
    width: auto;
    height: auto;
    margin-bottom: 6px;
    font-size: 12px;
    font-weight: 400;
    color: #787878;
    letter-spacing: -.5px;
`

const TextBold = styled.div `
    width: auto;
    height: auto;
    margin-bottom: 6px;
    font-size: 12px;
    font-weight: bold;
    color: #787878;
    letter-spacing: -.2px;
`

const TextDivider = styled.div `
    width: 1px;
    height: 16px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: #d3d3d3;
`

export default FooterInfo;