import styled from "styled-components";

import "./Header.css"
import {motion} from "framer-motion";
import {ReactComponent as Logo} from "../../svg/Logo.svg";
import {useLocation, useNavigate} from "react-router-dom";
import React, {memo, useCallback, useEffect, useRef, useState} from "react";
import ToHeaderWeb from "./web/ToHeaderWeb";
import ToHeaderPhone from "./phone/ToHeaderPhone";
import ReactModal from "react-modal";
import Modal from "../../component/modal/Modal";

export function Header() {
    let navigate = useNavigate()

    let currentLocation = useLocation()

    let RootRef = useRef<HTMLDivElement>(null)
    let logoContainerRef = useRef<HTMLDivElement>(null)
    let bigContainerRef = useRef<HTMLDivElement>(null)

    let [pageNo, setPageNo] = useState(0)

    let onCLickLogo = useCallback(() => {
        // navigate("/")
        // setPageNO(0)
        navigate("/Phone")
        setPageNo(2)
    }, [navigate])

    let onClickInternet = useCallback(() => {
        navigate("/Internet")
        setPageNo(1)
    }, [navigate])

    let onClickPhone = useCallback(() => {
        navigate("/Phone")
        setPageNo(2)
    }, [navigate])

    let onClickRental = useCallback(() => {
        navigate("/Rental")
        setPageNo(3)
    }, [navigate])

    let onResize = useCallback(() => {
        let currentWidthValue = window.innerWidth

        if (RootRef.current !== null) {
            if (currentWidthValue < 600) {
                // 모바일
                ToHeaderPhone(RootRef)
            } else {
                // 웹
                ToHeaderWeb(RootRef)
            }
        }

    }, [])
    
    let MemorizedModal = memo(Modal)

    useEffect(() => {
        onResize()

        switch (currentLocation.pathname) {
            case "/Internet" : {
                setPageNo(1)
                break
            }

            case "/Phone" : {
                setPageNo(2)
                break
            }

            case "/Rental" : {
                setPageNo(3)
                break
            }
        }

        window.addEventListener("resize", onResize)
        return () => window.removeEventListener("resize", onResize)
    }, [currentLocation.pathname, onResize])

    return <HeaderRoot ref={RootRef}>
        <LogoContainer ref={logoContainerRef} onClick={onCLickLogo}>
            <Logo fill={"#eeeb13"}></Logo>
        </LogoContainer>

        <div className={"header-right-container"} ref={bigContainerRef}>
            {/*<HeaderButton onClick={onClickInternet}*/}
            {/*              whileHover={{color: "#eeeb13"}}*/}
            {/*              animate={pageNo === 1 ? {color:"#eeeb13"} : {color:"#000000"}}*/}
            {/*>인터넷</HeaderButton>*/}
            <HeaderButton onClick={onClickPhone}
                          whileHover={{color: "#eeeb13"}}
                          animate={pageNo === 2 ? {color:"#eeeb13"} : {color:"#000000"}}
            >핸드폰</HeaderButton>
            {/*<HeaderButton onClick={onClickRental}*/}
            {/*              whileHover={{color: "#eeeb13"}}*/}
            {/*              animate={pageNo === 3 ? {color:"#eeeb13"} : {color:"#000000"}}*/}
            {/*>가전렌탈</HeaderButton>*/}
        </div>

        <MemorizedModal type={1} title={""} description={""}/>
    </HeaderRoot>
}

const HeaderRoot = styled.div `
    width: 90vw;
    height: auto;
    padding-left: .4rem;
    padding-right: .4rem;
    margin-top: 24px;
    display: flex;
    border-radius: 50px;
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    background: #ffffff;
    z-index: 10;
    box-shadow: 0 0 8px #8f8f8f40;
`

const LogoContainer = styled.div `
    width: auto;
    height: auto;
    margin-left: 16px;
    justify-content: center;
    align-items: center;
    align-content: center;
    cursor:pointer;
    position: relative;
`

const HeaderButton = styled(motion.button) `
    font-family: Lato-Light;
    color: #153448;
    background: transparent;
    border: 30px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 30px;
    cursor: pointer;
    margin-right: 20px;
    margin-left: 20px;
    letter-spacing: -.1rem;
`

const customModalStyles: ReactModal.Styles = {
    overlay: {
        backgroundColor: " rgba(0, 0, 0, 0.4)",
        width: "100%",
        height: "100vh",
        zIndex: "10",
        position: "fixed",
        top: "0",
        left: "0",
    },
    content: {
        width: "360px",
        height: "180px",
        zIndex: "150",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: "10px",
        boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.25)",
        backgroundColor: "white",
        justifyContent: "center",
        overflow: "auto",
    },
};

export default Header;