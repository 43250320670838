import styled from "styled-components";

export const InternetAgencyFormSection = () => {
    return <RootPage>
        <Container>
            <Title>구매하고 싶은 인터넷 상품을 선택해주세요</Title>
        </Container>
    </RootPage>
}

const RootPage = styled.section `
    width: 100%;
    padding-top: 120px;
    background-color: #ffffff;
`

const Container = styled.div `
    width: 1056px;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    box-sizing: border-box;
`

const Title = styled.h3 `
    font-size: 32px;
    letter-spacing: -.4px;
    line-height: 1.2;
`

export default InternetAgencyFormSection;