import styled from "styled-components";
import MaterialInputBox from "../../../component/Input/MaterialInputBox";
import DropdownButton, {Dropdown, PopupHandler} from "../../../component/select/DropdownButton";
import {memo, useCallback, useEffect, useRef} from "react";
import MaterialTextArea from "../../../component/Input/MaterialTextArea";
import PrimaryButton from "../../../component/button/PrimaryButton";

export const PhoneContactSection = () => {

    let currentUsedRef = useRef<PopupHandler>(null)
    let wantUseRef = useRef<PopupHandler>(null)
    let formRef = useRef<HTMLFormElement>(null)

    let firstLineRef = useRef<HTMLDivElement>(null)
    let secondLineRef = useRef<HTMLDivElement>(null)
    let thirdLineRef = useRef<HTMLDivElement>(null)

    let lineRefList = [firstLineRef, secondLineRef, thirdLineRef]

    let firstHalfRef = useRef<HTMLDivElement>(null)
    let secondHalfRef = useRef<HTMLDivElement>(null)
    let thirdHalfRef = useRef<HTMLDivElement>(null)
    let fourthHalfRef = useRef<HTMLDivElement>(null)

    let halfRefList = [firstHalfRef, secondHalfRef, thirdHalfRef, fourthHalfRef]

    let MemorizedCurrentUsed = memo(DropdownButton)
    let MemorizedWantUse = memo(DropdownButton)

    let currentUsed: Dropdown = {
        hint : "사용중인 통신사를 선택해주세요",
        nameList : ["SK", "KT", "LG", "알뜰폰"],
        funcList : []
    }

    let wantUse: Dropdown = {
        hint: "희망하시는 통신사를 선택해주세요",
        nameList : ["SK", "KT", "LG"],
        funcList : []
    }

    let goContact = useCallback(() => {
        window.open("http://pf.kakao.com/_iyUZn/chat")
    }, [])

    useEffect(() => {
        let onResize = () => {
            if (window.innerWidth > 750) {
                resizeScreen("600px", false)
            } else {
                if (window.innerWidth > 500) {
                    resizeScreen("400px", true)
                } else {
                    resizeScreen("300px", true)
                }
            }
        }

        onResize()

        function resizeScreen(size: string, isCollapse: boolean) {
            if (formRef.current !== null) {
                let current = formRef.current
                current.style.width = size
            }

            lineRefList.forEach((ref) => {
                if (ref.current !== null) {
                    ref.current.style.display = isCollapse ? "block" : "inline-flex"
                }
            }, [])

            halfRefList.forEach((ref) => {
                if (ref.current !== null) {
                    ref.current.style.width = isCollapse ? "100%" : "calc(50% - 15px)"
                }
            }, [])
        }

        window.addEventListener("resize", onResize)
        return () => window.removeEventListener("resize", onResize)
    }, []);

    return <Root>
        <SubTitleContainer>
            <SubTitleText>Contact Us</SubTitleText>
        </SubTitleContainer>
        <TitleContainer>
            <TitleText>상담을 예약 하세요!</TitleText>
        </TitleContainer>
        <TitleDescription>상담예약 하시면, 카카오톡 플러스 친구를 통한 상담이 시작됩니다.<br/>하단 내용을 채워주시면 빠른 상담에 많은 도움이 돼요!</TitleDescription>
        <Form ref={formRef} action={"#"} method={"post"}>
            <FormContainer ref={firstLineRef}>
                <HalfContainer ref={firstHalfRef}>
                    <MaterialInputBox type={"text"} hint={"이름"} name={"name"}/>
                </HalfContainer>
                <HalfContainer ref={secondHalfRef}>
                    <MaterialInputBox type={"tel"} hint={"핸드폰 번호"} name={"tel"}/>
                </HalfContainer>
            </FormContainer>
            <FormContainer ref={secondLineRef}>
                <FullContainer>
                    <MaterialInputBox type={"text"} hint={"개통 희망 기종(갤럭시 S25+ Ultra)"} name={"wantPhone"}/>
                </FullContainer>
            </FormContainer>
            <FormContainer ref={thirdLineRef}>
                <HalfContainer ref={thirdHalfRef}>
                    <MemorizedCurrentUsed key={1} ref={currentUsedRef}  hint={currentUsed.hint} nameList={currentUsed.nameList} funcList={currentUsed.funcList} />
                </HalfContainer>
                <HalfContainer ref={fourthHalfRef}>
                    <MemorizedWantUse key={2} ref={wantUseRef} hint={wantUse.hint} nameList={wantUse.nameList} funcList={wantUse.funcList}/>
                </HalfContainer>
            </FormContainer>
            <FormContainer>
                <FullContainer>
                    <MaterialTextArea hint={"문의 및 요청사항을 적어주세요"} name={"option"}/>
                </FullContainer>
            </FormContainer>
            <FormContainer>
                <FullContainer>
                    <PrimaryButton title={"상담받기"} onClick={goContact} fill={true}/>
                </FullContainer>
            </FormContainer>
        </Form>
    </Root>
}

const Root = styled.section `
    width: 100%;
    height: auto;
    display: block;
    box-sizing: border-box;
    background: #fffcec;
    margin-top: -3px;
    padding-top: 140px;
    padding-bottom: 140px;
    text-align: center !important;
`

const TitleContainer = styled.div `
    width: auto;
    height: auto;
    margin-top: 32px;
    margin-bottom: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const TitleText = styled.span `
    font-size: 32px;
    font-weight: bold;
    color: #222222;
`

const SubTitleContainer = styled.div `
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
`

const SubTitleText = styled.span `
    font-size: 24px;
    font-weight: bold;
    color: #d5b306;
`

const TitleDescription = styled.p `
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #919294;
`

const Form = styled.form `
    width: 600px;
    display: block;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
`

const FormContainer = styled.div `
    width: 100%;
    display: inline-flex;
    position: relative;
    box-sizing: border-box;
    gap: 30px;
`

const HalfContainer = styled.div `
    width: calc(50% - 15px);
    max-width: 100%;
    position: relative;
    flex: 0 0 auto;
    flex-wrap: wrap;
    display: block;
    margin-top: 1rem;
`

const FullContainer = styled.div `
    width: 100%;
    max-width: 100%;
    position: relative;
    flex: 0 0 auto;
    flex-wrap: wrap;
    display: block;
    margin-top: 1rem;
`



export default PhoneContactSection;