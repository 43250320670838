import styled from "styled-components";

export const SectionContainer = styled.div `
    width: 1080px;
    max-width: 100%;
    height: 100%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
    display: block;
    box-sizing: border-box;
`

export const AutoSectionContainer = styled.div `
    width: 1080px;
    max-width: 100%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
`


export default SectionContainer;