import {useEffect} from "react";
import styled from "styled-components";
import {useLocation} from "react-router-dom";
import InternetAgencyFormSection from "./section/InternetAgencyFormSection";
import InternetAgencyFooterSection from "./section/InternetAgencyFooterSection";

export const InternetAgency = () => {

    const {state} = useLocation()

    useEffect(() => {

    }, []);

    return <RootPage>
        <InternetAgencyFormSection/>

        <InternetAgencyFooterSection/>
    </RootPage>
}

const RootPage = styled.div `
    width: 100%;
    height: 1800px;
    display: block;
    background: #ffffff;
    z-index: 1;
`

export default InternetAgency;