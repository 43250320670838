import styled from "styled-components";
import {motion} from "framer-motion";
import {useCallback, useState} from "react";


export const ProductItem = (props : {content: string, logo: JSX.Element|undefined, isScale: boolean, onClick: () => void}) => {

    let [isHover, setHover] = useState(false)

    let imageVariants = {
        "normal" : {
            scale: props.isScale ? .6 : 1,
            transition: {duration : .4, type: "spring"}
        },
        "scale" : {
            scale: props.isScale ? .8 : 1.2,
            transition: {duration : .4, type: "spring"}
        }
    }

    let EnterEvent = useCallback(() => {
        setHover(true)
    }, [])

    let LeaveEvent = useCallback(() => {
        setHover(false)
    }, [])

    return <Item
        whileHover={{border: "1px solid #ffde00", transition: {duration : 0}}}
        onMouseEnter={EnterEvent}
        onMouseLeave={LeaveEvent}
        onClick={props.onClick}
    >
        <WrapImage
            initial={"normal"}
            variants={imageVariants}
            animate={isHover ? "scale" : "normal"}
        >
            {
                props.logo
            }
        </WrapImage>
        <Text>{props.content}</Text>
    </Item>
}

const Item = styled(motion.li) `
    width: 143.7px;
    display: block;
    cursor: pointer;
    border: 1px solid #dad8d8;
    border-radius: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: white;
`

const WrapImage = styled(motion.div) `
    width: auto;
    height: 52px;
    margin: 0 auto 10px;
`

const Text = styled.span `
    width: 100%;
    height: auto;
    letter-spacing: -.3px;
    color: #22272a;
    font-weight: bold;
    font-size: 18px;
    font-family: Lato-Light;
    margin: 0 auto;
`

export default ProductItem;