import './App.css';

import React, {memo, useEffect, useState} from 'react';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./page/home/Home";
import Internet from "./page/internet/Internet";
import Header from "./page/header/Header";
import Phone from "./page/phone/Phone";
import Rental from "./page/rental/Rental";
import InternetAgency from "./page/internet/agency/InternetAgency";
import FooterInfo from "./page/footer/FooterInfo";

const App = () => {

  const MemorizedHeader = memo(Header)
  const MemorizedHome = memo(Home)

  const MemorizedInternet = memo(Internet)
  const MemorizedInternetAgency = memo(InternetAgency)

  const MemorizedPhone = memo(Phone)
  const MemorizedRental = memo(Rental)

  const MemorizedFooter = memo(FooterInfo)


  return <>
    <BrowserRouter>
      <MemorizedHeader/>
      <Routes>
        <Route path="/" Component={MemorizedHome}/>
        <Route path="/internet/agency" Component={MemorizedInternetAgency} />
        <Route path="/internet" Component={MemorizedInternet}/>
        <Route path="/phone" Component={MemorizedPhone} />
        <Route path="/rental" Component={MemorizedRental} />
      </Routes>
      <MemorizedFooter/>
    </BrowserRouter>
  </>
}

export default App;
