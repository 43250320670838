import {motion} from "framer-motion";
import styled from "styled-components";
import FooterButton from "../../component/button/FooterButton";
import React, {memo, useEffect} from "react";

import "../internet/Internet.css"

import banner from "../../image/banner/rdd_banner.png"
import InternetAgencySection from "./section/InternetAgencySection";
import {Outlet} from "react-router-dom";

export function Internet() {

    const MemorizedAgencySection = memo(InternetAgencySection)

    useEffect(() => {

    }, [])

    return <RootPage>
        <HeadDividerContainer/>
        <img className={"banner-cover"} src={banner} />
        <MemorizedAgencySection/>
    </RootPage>
}

const RootPage = styled(motion.div) `
    width: 100%;
    height: 200%;
    display: block;
    margin: 0;
    border: none;
    background: transparent;
`

const HeadDividerContainer = styled.div `
    width: 100%;
    height: 5em;
    margin: 0;
    background: #ffde00;
`



export default Internet;