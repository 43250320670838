import styled from "styled-components";
import SectionTitle from "../../../component/text/SectionTitle";
import ProductItem from "../../../component/list/item/ProductItem";
import { ReactComponent as KTLogo } from "../../../svg/ktlogo.svg";
import { ReactComponent as LGULogo } from "../../../svg/lgu+logo.svg";
import { ReactComponent as SKBLogo } from "../../../svg/SKBLogo.svg";
import { ReactComponent as SKLogo } from "../../../svg/SKLogo.svg";
import { ReactComponent as SkylifeLogo } from "../../../svg/SkylifeLogo.svg";
import { ReactComponent as LGHelloVisionLogo } from "../../../svg/LGHellovisionLogo.svg";
import { ReactComponent as HCNLogo } from "../../../svg/HCNLogo.svg";
import {useCallback} from "react";
import {useNavigate} from "react-router-dom";

export const InternetAgencySection = () => {

    const navigate = useNavigate()

    function onClickAgency(agency: number) {
        navigate("/internet/agency", {state : {agency : agency}})

        switch (agency) {
            case 1 : // KT

                return
            case 2 : // LGU+
                return
            case 3 : // SKB
                return
            case 4 : // SK
                return
            case 5 : // SKYLIFE
                return
            case 6 : // LG HelloVision
                return
            case 7 : // SKY HCN
                return
        }
    }

    return <AgencySection>
        <AgencyContainer>
            <AgencyListContainer>
                <AgencyListContainerInner>
                    <SectionTitle content={"통신사를 선택하면 요금표가 나와요!"}/>
                    <AgencyList>
                        <ProductItem content={"KT"} logo={<KTLogo/>} isScale={true} onClick={() => {onClickAgency(1)}}/>
                        <ProductItem content={"LGU+"} logo={<LGULogo/>} isScale={true} onClick={() => {onClickAgency(2)}}/>
                        <ProductItem content={"SKB"} logo={<SKBLogo/>} isScale={true} onClick={() => {onClickAgency(3)}}/>
                        <ProductItem content={"SK"} logo={<SKLogo/>} isScale={true} onClick={() => {onClickAgency(4)}}/>
                        <ProductItem content={"SkyLife"} logo={<SkylifeLogo/>} isScale={true} onClick={() => {onClickAgency(5)}}/>
                        <ProductItem content={"LG HelloVision"} logo={<LGHelloVisionLogo/>} isScale={false} onClick={() => {onClickAgency(6)}}/>
                        <ProductItem content={"SKY HCN"} logo={<HCNLogo/>} isScale={false} onClick={() => {onClickAgency(7)}}/>
                    </AgencyList>
                </AgencyListContainerInner>
            </AgencyListContainer>
        </AgencyContainer>
    </AgencySection>
}

const AgencySection = styled.section `
    width: 100%;
    height: auto;
    padding-top: 32px;
    padding-bottom: 32px;
    background-color: #f9fafc;
`

const AgencyContainer = styled.div `
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    width: 1080px;
`

const AgencyListContainer = styled.div `
    flex: 1;
    border: 0;
    font-family: SUIT, Noto Sans KR, sans-serif;
    font-size: 100%;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
    word-break: keep-all;
`

const AgencyListContainerInner = styled.div `
    background: transparent;
    border: 0;
    box-shadow: none;
    padding: 12px 0;
`

const AgencyList = styled.ul `
    gap: 10px;
    list-style: none;
    flex-wrap: wrap;
    display: flex;
    padding-inline-start: 0;
`

export default InternetAgencySection;