import styled from "styled-components";

export function Rental() {
    return <RootPage>

    </RootPage>
}

const RootPage = styled.div `
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: none;
`

export default Rental;