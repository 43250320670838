import styled from "styled-components";
import {motion} from "framer-motion";
import banner from "../../image/banner/rdd_banner.png";
import React, {memo} from "react";
import PhoneBenefitSection from "./section/PhoneBenefitSection";
import PhoneContactSection from "./section/PhoneContactSection";

export function Phone() {

    let MemorizedBenefit = memo(PhoneBenefitSection)
    let MemorizedContact = memo(PhoneContactSection)

    return (<RootPage>
        <HeadDividerContainer/>
        <img className={"banner-cover"} src={banner}/>
        <MemorizedBenefit/>
        <MemorizedContact/>
    </RootPage>)
}

const RootPage = styled(motion.div)`
    width: 100%;
    height: auto;
    display: block;
    margin: 0;
    padding: 0;
    border: none;
    background: white;
`

const HeadDividerContainer = styled.div `
    width: 100%;
    height: 5em;
    margin: 0;
    background: #ffde00;
`

export default Phone;