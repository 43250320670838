import {RefObject} from "react";

export function ToHeaderPhone(rootRef: RefObject<HTMLDivElement>) {
    if (rootRef.current !== null) {
        let {current: root} = rootRef

        root.style.width = "100%"

        root.style.padding = "0"
        root.style.marginTop = "0"

        root.style.borderRadius = "0"
    }
}

export default ToHeaderPhone;