import {motion} from "framer-motion";
import styled from "styled-components";
import {useCallback, useRef, useState} from "react";

export const MaterialTextArea = (props: {hint: string, name: string}) => {
    let inputRef = useRef<HTMLTextAreaElement>(null)
    let [isEmpty, setStatus] = useState(true)
    let isFocus = false

    let OnChange = useCallback(() => {
        checkValue()
    }, [])

    let OnFocus = useCallback(() => {
        isFocus = true
        setStatus(false)
    }, [])

    let OnBlur = useCallback(() => {
        isFocus = false
        checkValue()
    }, [])

    function checkValue() {
        if (inputRef.current !== null) {
            let value = inputRef.current.value

            if (value === "") {
                if (!isFocus) setStatus(true)
            }

            else setStatus(false)
        }
    }

    let labelVariants = {
        "isEmpty" : {
            left: "0",
            top: "0",
            scale: 1,
            opacity : "1",
            transition: {
                duration : 0.2
            }
        },
        "isNotEmpty" : {
            left: "4px",
            top: "-7.5px",
            scale : .75,
            opacity: ".8",
            transition: {
                duration : 0.2,
            }
        }
    }

    return <Root>
        <TextArea ref={inputRef} name={props.name} onChange={OnChange} onFocus={OnFocus} onBlur={OnBlur} />
        <Label htmlFor={props.name} variants={labelVariants} initial={"isEmpty"} animate={isEmpty ? "isEmpty" : "isNotEmpty"}>{props.hint}</Label>
    </Root>
}

const Root = styled.div `
    width: 100%;
    display: block;
    position: relative;
    box-sizing: border-box;
`

const TextArea = styled(motion.textarea) `
    width: 100%;
    height: calc(12rem + 2px);
    padding-top: 30px;
    padding-left: 15px;
    margin: 0 auto;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    font-family: "Noto Sans Batak";
    line-height: 1.5;
    color: #383839;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 10px;
    outline-color: #d5b306;
    resize: none;
`

const Label = styled(motion.label) `
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding-top: 1.25rem;
    padding-left: 1rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    color: #60605f;
`

export default MaterialTextArea;