import {RefObject} from "react";

export function ToHeaderWeb(rootRef: RefObject<HTMLDivElement>) {
    if (rootRef.current !== null) {
        let {current: root} = rootRef

        root.style.left = "50%"
        root.style.width = "90%"
        root.style.transform = "translate(-50%, 0)"

        root.style.paddingLeft = ".4rem"
        root.style.paddingRight = ".4rem"
        root.style.marginTop = "24px"

        root.style.borderRadius = "50px"
    }
}

export default ToHeaderWeb;