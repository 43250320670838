import styled from "styled-components";
import { BsPersonPlusFill } from "react-icons/bs";
import { BiSolidPhoneCall } from "react-icons/bi";
import {useEffect, useRef} from "react";

export const InternetAgencyFooterSection = () => {
    return <RootPage>
        <FooterContainer>
            <ContentContainer>
                <PriceListContainer>
                    <PriceListLine>
                        <PriceTitle content={"결합 전 요금"} color={"#5e5e5e"}/>
                        <PriceValue value={"38500원"} color={"#5e5e5e"}/>
                    </PriceListLine>
                    <PriceListLine>
                        <PriceTitle content={"휴대폰 결합 요금"} color={"black"}/>
                        <PriceValue value={"38500원"} color={"black"}/>
                    </PriceListLine>
                </PriceListContainer>

                <OptionContainer>
                    <OptionButtonContainer>
                        <OptionRegisterButton>
                            <BsPersonPlusFill/>
                            <span>가입 하기</span>
                        </OptionRegisterButton>
                        <OptionCallButton>
                            <BiSolidPhoneCall/>
                            <span>상담 하기</span>
                        </OptionCallButton>
                    </OptionButtonContainer>
                </OptionContainer>
            </ContentContainer>
        </FooterContainer>
    </RootPage>
}

const RootPage = styled.div `
    box-sizing: border-box;
    background-color: #fff;
    bottom: 0;
    box-shadow: 0 0 16px #0000001a;
    left: 0;
    padding: 21px 16px;
    position: fixed;
    width: 100%;
    z-index: 101;
`

const FooterContainer = styled.div `
    width: 1056px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    box-sizing: border-box;
`

const ContentContainer = styled.div `
    height: auto;
    align-items: center;
    display: flex;
    gap: 56px;
    justify-content: space-between;
`

const PriceListContainer = styled.div `
    flex: 1;
    display: block;
    height: auto;
`

const PriceListLine = styled.div `
    display: block;
    box-sizing: border-box;
    line-height: 1.4;
    height: auto;
    font-size: 20px;
    letter-spacing: -.4px;
    padding: 0;
    margin: 0 0 4px;
`

const PriceTitle = (props : {content: string, color: string}) => {
    let compoRef = useRef<HTMLSpanElement>(null)

    useEffect(() => {
        if (compoRef.current !== null) {
            compoRef.current.style.color = props.color
        }
    }, []);

    return <PriceTitleText ref={compoRef}>
        {props.content}
    </PriceTitleText>
}

const PriceTitleText = styled.span `
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -.4px;
    line-height: 1.2;
`

const PriceValue = (props: {value: string, color: string}) => {
    let compoRef = useRef<HTMLSpanElement>(null)

    useEffect(() => {
        if (compoRef.current !== null) {
            compoRef.current.style.color = props.color
        }
    }, []);


    return <PriceValueText ref={compoRef}>
        {
            props.value
        }
    </PriceValueText>
}

const PriceValueText = styled.span `
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -.4px;
    line-height: 1.2;
    float: right;
`

const OptionContainer = styled.div `
    flex-flow: column;
    gap: 14px 0;
    display: block;
`

const OptionButtonContainer = styled.div `
    display: flex;
    gap: 12px;
`

const OptionRegisterButton = styled.div `
    width: 202px;
    height: 44px;
    display: flex;
    background-color: #ffffff;
    border: 1px solid #e3c600;
    border-radius: 3px;
    color: #e3c600;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    cursor:pointer;
    gap: 8px;
`

const OptionCallButton = styled.div `
    width: 202px;
    height: 44px;
    display: flex;
    background-color: #e3c600;
    border: 1px solid #e3c600;
    border-radius: 3px;
    color: #ffffff;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    cursor:pointer;
    gap: 8px;
`

export default InternetAgencyFooterSection;