import styled from "styled-components";
import {motion} from "framer-motion";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import React, {forwardRef, useCallback, useEffect, useRef, useState} from "react";

export interface Dropdown {
    hint: string,
    nameList: string[],
    funcList: (() => void)[]
}

export interface PopupHandler {}

export const DropdownButton = forwardRef<PopupHandler, Dropdown>((props, ref) => {
    let [isOpen, setStatus] = useState(false)
    let [hint, setHint] = useState("")
    let [clickIdx, setClickIdx] = useState(0)

    let dropdownRef = useRef<HTMLDivElement>(null)
    let labelRef= useRef<HTMLLabelElement>(null)
    let boxRef = useRef<HTMLDivElement>(null)

    let boxVariants = {
        "isOpen" : {
            border : "2px solid #d5b306",
            borderColor : "#d5b306",
            transition : {
                duration : .2
            },
            height : "auto",
            zIndex: 100
        },
        "isNotOpen" : {
            border : "1px solid #ced4da",
            borderColor : "#ced4da",
            height: "calc(3rem + 15px)",
            zIndex : 2,
        }
    }

    let arrowVariants = {
        "initial" : {
            rotate : "0deg",
            translateX : "-7px",
            translateY : "-50%"
        },
        "isOpen" : {
            rotate : "180deg",
            translateX : "-7px",
            translateY : "-50%"
        },
        "isNotOpen" : {
            rotate : "360deg",
            translateX : "-7px",
            translateY : "-50%"

        }
    }

    let contentVariants = {
        "isClicked" : {
            background : "#dfd8b7"
        },
        "isNotClicked" : {
            background : "#fff"
        }
    }

    let labelVariants = {
        "isClicked" : {
            color : "#60605f"
        },
        "isNotClicked" : {
            color : "#60605f"
        }
    }

    let onClickBox = useCallback(() => {
        setStatus(!isOpen)
    }, [isOpen])

    let handleClicked = useCallback((e: MouseEvent) => {
        if (dropdownRef.current !== null) {
            if (!(dropdownRef.current.contains(e.target as Node))) {
                setStatus(false)
            }
        }
    }, [])

    let onClickContent = useCallback((name: string, idx: number) => {
        setHint(name)
        setClickIdx(idx + 1)
    }, [])

    useEffect(() => {
        setHint(props.hint)

        window.addEventListener("mousedown", handleClicked)
        return () => window.removeEventListener("mousedown", handleClicked)
    }, []);

    return <Root ref={dropdownRef}>
        <BoxContainer variants={boxVariants} animate={isOpen ? "isOpen" : "isNotOpen"} onClick={onClickBox}>
            <Box ref={boxRef}>
                <ArrowContainer variants={arrowVariants} animate={isOpen ? "isOpen" : "isNotOpen"}>
                    <ArrowDown size={24}/>
                </ArrowContainer>
                <Label ref={labelRef}>{hint}</Label>
            </Box>

            {
                props.nameList.map((name, idx) => (
                    <Box key={"DropdownButton" + idx} variants={contentVariants} onClick={() => {
                        onClickContent(name, idx)
                    }} animate={clickIdx === (idx + 1) ? "isClicked" : "isNotClicked"}>
                        <Label variants={labelVariants} animate={clickIdx === (idx + 1) ? "isClicked" : "isNotClicked"}>{name}</Label>
                    </Box>
                ))
            }
        </BoxContainer>
    </Root>
});

const Root = styled(motion.div) `
    width: calc(100% + 19px);
    height: calc(3rem + 15px);
    display: block;
    position: relative;
    box-sizing: border-box;
`

const BoxContainer = styled(motion.div) `
    width: calc(100%);
    height: calc(3rem + 15px);
    display: block;
    position: absolute;
    box-sizing: border-box;
    border: 1px solid #ced4da;
    background-color: #fff;
    border-radius: 10px;
    background-clip: padding-box;
    overflow: hidden;
    z-index: 100;
`

const Box = styled(motion.div) `
    width: calc(100% + 2px);
    height: calc(3rem + 15px);
    display: block;
    position: relative;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #5f5f60;
    appearance: none;
    cursor: pointer;
    z-index: 1;
`

const Label = styled(motion.label) `
    position: absolute;
    padding-left: 15px;
    transform: translate(0%, -50%);
    top: 50%;
    left: 0;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    color: #60605f;
    z-index: 2;
`

const ArrowContainer = styled(motion.div) `
    width: 24px;
    height: 24px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-7px, -50%);
`

const ArrowDown = styled(MdOutlineKeyboardArrowDown) `
    width: 24px;
    height: 24px;
    fill: #5f5f60;
`

export default DropdownButton;