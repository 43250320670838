import styled from "styled-components";

export const SectionTitle = (props: {content: string}) => {
    return <Text>
        {props.content}
    </Text>
}

const Text = styled.h3 `
    color: #21272a;
    display: flex;
    font-size: 20px;
    font-weight: 700;
    justify-content: space-between;
    letter-spacing: -.4px;
    line-height: 1.2;
    margin-bottom: 20px;
`

export default SectionTitle;