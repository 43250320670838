import {motion} from "framer-motion";
import styled from "styled-components";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

export function Home() {
    let navigate = useNavigate()

    useEffect(() => {
        navigate("/Phone")
    }, [])

    return <RootPage>

    </RootPage>
}

const RootPage = styled(motion.div) `
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: none;
`

export default Home;