import styled from "styled-components";
import {motion} from "framer-motion";
import { GiTalk } from "react-icons/gi";
import {useCallback} from "react";

export const ContactKakao = () => {
    let MoveKakaoChannel = useCallback(() => {
        window.open("http://pf.kakao.com/_iyUZn")
    }, [])

    return <Root onClick={MoveKakaoChannel}>
        <ContentContainer>
            <ImageContainer>
                <GiTalk color={"#229df4"} size={32}/>
            </ImageContainer>
            <Text>상담받기</Text>
        </ContentContainer>
    </Root>
}

const Root = styled(motion.div) `
    width: 75px;
    height: 75px;
    display: block;
    position: fixed;
    bottom: 10%;
    right: 5%;
    border: 2px solid #229df4;
    border-radius: 50px;
    background: white;
    z-index: 1000;
`

const ContentContainer = styled.div `
    width: 100%;
    height: 100%;
    display: block;
    text-align: center;
    align-content: center;
    cursor: pointer;
`

const ImageContainer = styled.div `
    width: 100%;
    height: auto;
    display: block;
    margin: 0;
    line-height: .1;
`

const Text = styled.span `
    width: auto;
    height: auto;
    font-weight: bold;
    font-size: 12px;
    color: #229df4;
`

export default ContactKakao;