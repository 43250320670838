import styled from "styled-components";
import { PiMoneyWavy } from "react-icons/pi";
import { IoMdAlarm } from "react-icons/io";
import { TbReceipt } from "react-icons/tb";
import { TbShieldCancel } from "react-icons/tb";
import { RiRobot3Line } from "react-icons/ri";
import {useEffect, useRef} from "react";

export const PhoneBenefitSection = () => {

    let firstRef = useRef<HTMLDivElement>(null)
    let secondRef = useRef<HTMLDivElement>(null)
    let thirdRef = useRef<HTMLDivElement>(null)
    let fourthRef = useRef<HTMLDivElement>(null)
    let fifthRef = useRef<HTMLDivElement>(null)

    let refList = [firstRef, secondRef, thirdRef, fourthRef, fifthRef]

    useEffect(() => {
        let onResize = () => {
            if (window.innerWidth > 1075) {
                resizeBox("33%")
            } else {
                if (window.innerWidth < 730) {
                    resizeBox("100%")
                } else {
                    resizeBox("50%")
                }
            }
        }

        onResize()

        function resizeBox(size: string) {
            refList.forEach((ref) => {
                if (ref.current !== null) {
                    ref.current.style.width = size
                }
            }, [])
        }

        window.addEventListener("resize", onResize)
        return () => window.removeEventListener("resize", onResize)
    }, [])

    return <Root>
        <SubTitleContainer>
            <SubTitleText>Phone Benefit</SubTitleText>
        </SubTitleContainer>
        <TitleContainer>
            <TitleText>5가지의 혜택을 받아보세요</TitleText>
        </TitleContainer>
        <ContentContainer>
            <ContentBox ref={firstRef}>
                <TextBox>
                    <IconBox>
                        <PiMoneyWavy size={60} color={"#ffffff"}/>
                    </IconBox>
                    <ContentTitle>링딩동 공식지원금!</ContentTitle>
                    <ContentDesc>최대 50만원(기기값 할인으로 적용) 상당의<br/>링딩동 비밀지원금과 함께해요</ContentDesc>
                </TextBox>
            </ContentBox>
            <ContentBox ref={secondRef}>
                <TextBox>
                    <IconBox>
                        <IoMdAlarm size={60} color={"#ffffff"}/>
                    </IconBox>
                    <ContentTitle>실시간 알림을 받으세요!</ContentTitle>
                    <ContentDesc>필요하고 유익한 정보를<br/>고정된 경로로 안전하게 알려줘요</ContentDesc>
                </TextBox>
            </ContentBox>
            <ContentBox ref={thirdRef}>
                <TextBox>
                    <IconBox>
                        <TbReceipt size={60} color={"#ffffff"}/>
                    </IconBox>
                    <ContentTitle>빠른 견적체크!</ContentTitle>
                    <ContentDesc>곧 지원될 앱에서<br/>견적을 빠르게 계산받을 수 있어요</ContentDesc>
                </TextBox>
            </ContentBox>
            <ContentBox ref={fourthRef}>
                <TextBox>
                    <IconBox>
                        <TbShieldCancel size={60} color={"#ffffff"}/>
                    </IconBox>
                    <ContentTitle>당신을 지켜드려요!</ContentTitle>
                    <ContentDesc>부가서비스 가입 및 스팸성 전화 문자 등<br/>악성 연락처로부터 지킬게요</ContentDesc>
                </TextBox>
            </ContentBox>
            <ContentBox ref={fifthRef}>
                <TextBox>
                    <IconBox>
                        <RiRobot3Line size={60} color={"#ffffff"}/>
                    </IconBox>
                    <ContentTitle>365일 함께해요!</ContentTitle>
                    <ContentDesc>카카오톡 및 전화로<br/>오전 10시 ~ 오후 7시까지 상담이 가능해요</ContentDesc>
                </TextBox>
            </ContentBox>
        </ContentContainer>
    </Root>
}

const Root = styled.section `
    width: 100%;
    height: auto;
    display: block;
    box-sizing: border-box;
    background: #fffcec;
    margin-top: -3px;
    padding-top: 140px;
    padding-bottom: 140px;
`

const TitleContainer = styled.div `
    width: auto;
    height: auto;
    margin-top: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const TitleText = styled.span `
    font-size: 32px;
    font-weight: bold;
    color: #222222;
`

const SubTitleContainer = styled.div `
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
`

const SubTitleText = styled.span `
    font-size: 24px;
    font-weight: bold;
    color: #d5b306;
`

const ContentContainer = styled.div `
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(3rem * 1);
    margin-right: calc(1.5rem / 2);
    margin-left: calc(1.5rem / 2);
    justify-content: center !important;
`

const ContentBox = styled.div `
    max-width: 100%;
    width: 33%;
    display: block;
    padding-right: calc(1.5rem / 2);
    padding-left: calc(1.5rem / 2);
    margin-top: 3rem;
    box-sizing: border-box;
    padding-top: 1.5rem !important;
    text-align: center !important;
`

const TextBox = styled.div `
    display: block;
    border-radius: 10px !important;
    background-color: #ffffff !important;
    padding: 3rem 1.5rem 1.5rem !important;
    position: relative !important;
    box-sizing: border-box;
`

const IconBox = styled.div `
    width: 100px;
    height: 100px;
    background: linear-gradient(to bottom right, #ffeed8, #d5b306);
    border-radius: 50% !important;
    align-items: center !important;
    justify-content: center !important;
    transform: translate(-50%, -50%) !important;
    left: 50% !important;
    top: 0 !important;
    position: absolute !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    display: inline-flex !important;
    box-sizing: border-box;
`

const ContentTitle = styled.h5 `
    display: block;
    margin-block-start: 1.67em;
    margin-block-end: 1.67em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    margin-bottom: 1rem !important;
    margin-top: 1.5rem !important;
    font-weight: 500 !important;
    font-size: 1.25rem;
    line-height: 1.2;
    color: #605834;
    box-sizing: border-box;
`

const ContentDesc = styled.p `
    box-sizing: border-box;
    margin-bottom: 0 !important;
    margin-top: 0;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    unicode-bidi: isolate;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #919294;
`

export default PhoneBenefitSection;